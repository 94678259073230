.row-full {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  margin-bottom: 50px;
  .item-club {
    img {
      width: 100%;
    }
  }
  .slick-arrow {
    position: absolute;
    top: calc(50% - 25px);
    font-size: 50px;
    color: white;
    z-index: 1;
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
    }
  }
}
.bloc_custom {
  display: none;
}
.price_bloc {
  float: left;
  line-height: 35px;
}
.selectField {
  height: 36px;
  border-radius: 50px;
  padding: 6px;
  font-size: 12px;
  background: #FFFFFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=) no-repeat scroll right 0.5rem center/1.25rem 1.25rem;
}
.select_small {
  font-size: 10px;
  color: #000000 !important;
}
.require_text {
  font-weight: 400 !important;
  color: #959595;
  font-size: 10px;
}
.customization-select {
  font-size: 14px;
  label {
    font-weight: 400;
  }
}